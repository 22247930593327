export const getFromSessionStorage = (key: string) => {
  return sessionStorage.getItem(key);
};
export const getRoundedNumber = (num: number) => {
  return Math.round(num);
};

export const getFromLocalStorage = (key: string) => {
  return localStorage.getItem(key);
};

export const setInSessionStorage = (key: string, value: string) => {
  sessionStorage.setItem(key, value);
};

export const setInLocalStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const convertStringToDate = (date: string) => {
  return new Date(date).toISOString();
};

export const convertISOToReadableDate = (isoDate: string) => {
  return isoDate.substring(0, 10).split("-").reverse().join("-");
};

export const addDaysToDate = (date: string, numberOfDaysToBeAdded: number) => {
  var dateObj = new Date(date);
  dateObj.setHours(9);
  dateObj.setMinutes(0);
  dateObj.setMilliseconds(0);
  dateObj.setDate(dateObj.getDate() + numberOfDaysToBeAdded);
  return dateObj.toISOString();
};

export const getSkuDescription = (sku: any) => {
  const returnValue = [];
  if (sku.watt) {
    returnValue.push({ key: "Watt", value: sku.watt });
  }
  if (sku.colorTemp) {
    returnValue.push({ key: "Led Color", value: sku.colorTemp });
  }

  if (sku.bodyColor) {
    returnValue.push({ key: "Body Color", value: sku.bodyColor });
  }

  if (sku.cutout) {
    returnValue.push({ key: "Cutout", value: sku.cutout });
  }
  if (sku.driverOutput) {
    returnValue.push({ key: "Driver Output", value: sku.driverOutput });
  }
  if (sku.dimension) {
    returnValue.push({ key: "Dimension", value: sku.dimension });
  }
  if (sku.beamAngle) {
    returnValue.push({ key: "Beam Angle", value: sku.beamAngle });
  }
  if (sku.ledUsed) {
    returnValue.push({ key: "Led Used", value: sku.ledUsed });
  }
  if (sku.bodyType) {
    returnValue.push({ key: "Body Type", value: sku.bodyType });
  }
  if (sku.isWaterProof) {
    returnValue.push({ key: "WaterProof", value: "yes" });
  }
  if (sku.ipRating) {
    returnValue.push({ key: "IP Rating", value: sku.ipRating });
  }

  if (sku.customField.length > 0) {
    sku.customField.map((input: any) => {
      //@ts-ignore
      if (input.length == 2) {
        returnValue.push({ key: input[0], value: input[1] });
      }
    });
  }
  return returnValue;
};

export const formatRecievedDescription = (description: any): string => {
  var res = "";

  if (description) {
    for (let i = 0; i < description.length; i++) {
      res += `${description[i].key} : ${description[i].value}`;
    }
  }
  return res;
};

// write a function to return a random 16 character hex string
export const generateRandomString = () => {
  return Math.random().toString(16).substring(2, 16);
};

var a = [
  "",
  "one ",
  "two ",
  "three ",
  "four ",
  "five ",
  "six ",
  "seven ",
  "eight ",
  "nine ",
  "ten ",
  "eleven ",
  "twelve ",
  "thirteen ",
  "fourteen ",
  "fifteen ",
  "sixteen ",
  "seventeen ",
  "eighteen ",
  "nineteen ",
];
var b = [
  "",
  "",
  "twenty",
  "thirty",
  "forty",
  "fifty",
  "sixty",
  "seventy",
  "eighty",
  "ninety",
];

export function inWords(num: any) {
  if ((num = num.toString()).length > 9) return "overflow";

  let n: any = ("000000000" + num)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);

  if (!n) return;
  var str = "";
  str +=
    n[1] != 0
      ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
      : "";
  str +=
    n[2] != 0
      ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
      : "";
  str +=
    n[3] != 0
      ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
      : "";
  str +=
    n[4] != 0
      ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
      : "";
  str +=
    n[5] != 0
      ? (str != "" ? "and " : "") +
        (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
        "only "
      : "";
  return str;
}
