import { createTheme } from "@mui/material";

export const BaseTheme = createTheme({
  typography: {
    fontFamily: "Nunito, sans-serif",
    fontWeightRegular: 400,
    fontWeightLight: 300,
    fontWeightMedium: 600,
    fontWeightBold: 800,
  },
});
