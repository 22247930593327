import "./App.css";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Login from "./pages/Login";
import { BaseTheme } from "./theme/BaseTheme";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { backendConfig } from "./config/constants";
//import Sidebar from "./components/Sidebar";
//import AppRoutes from "./Routes/Routes";
import { lazy, Suspense } from "react";
import ListsSkeleton from "./components/skeletons/ListsSkeleton";

const Sidebar = lazy(() => import("./components/Sidebar"));
const AppRoutes = lazy(() => import("./Routes/Routes"));

axios.defaults.baseURL = backendConfig.baseURL;

function App() {
  const location = useLocation();

  if (location.pathname === "/" || location.pathname === "/login") {
    return (
      <ThemeProvider theme={BaseTheme}>
        <Login />
      </ThemeProvider>
    );
  }
  return (
    <ThemeProvider theme={BaseTheme}>
      <CssBaseline />
      <Suspense fallback={<ListsSkeleton />}>
        <Sidebar>
          <AppRoutes />
        </Sidebar>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
