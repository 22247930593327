import {
  Button,
  Card,
  Snackbar,
  styled,
  Alert,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/material";
import React, { useState } from "react";
import validator from "validator";
import axios from "axios";
import { auth, CompanyName, UserType } from "../config/constants";
import {
  getFromSessionStorage,
  setInSessionStorage,
} from "../utils/HelperFunctions";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const MainBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    margin: "1rem",
    height: "90vh",
  },
  alignItems: "center",
  height: "100vh",
  margin: "2rem",
}));

const LogoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: "1",
  height: "100%",
  justifyContent: "center",
}));

const LoginFormBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "8px",
  flex: "1",
});

function Login() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [phoneNumberHelperText, setphoneNumberHelperText] = useState("");

  const [password, setpassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState("");

  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info" as any);

  const [changePasswordDialogOpen, setChangePasswordDialogOpen] =
    useState(false);
  const [changePasswordText, setChangePasswordText] = useState("");
  const [defaultPasswordError, setdefaultPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSnackbarClose = () => {
    setOpen(false);
  };

  const handleSearchWithEnterKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      loginSubmitButtonHandler();
    }
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const loginSubmitButtonHandler = () => {
    if (!validator.isMobilePhone(phoneNumber, ["en-IN"])) {
      setPhoneNumberError(true);
      setphoneNumberHelperText("Invalid phone number");
      return;
    }
    if (
      validator.isEmpty(password) ||
      !validator.isLength(password, { min: 6, max: 20 })
    ) {
      setPasswordError(true);
      setPasswordHelperText(
        "Invalid password. Password must have atleast 6 characters."
      );
      return;
    } else {
      axios
        .post(auth.signInUrl, {
          mobileNo: phoneNumber,
          password: password,
        })
        .then((response) => {
          console.log("Logged in");
          setInSessionStorage("isLoggedIn", "true");
          setInSessionStorage("expiry", response.data.result["expires"]);
          setInSessionStorage("token", response.data.result["token"]);
          setInSessionStorage("branchID", response.data.result["branchID"]);
          setInSessionStorage("userType", response.data.result["userType"]);
          setInSessionStorage(
            "branchDetails",
            JSON.stringify(response.data.result["branchInfo"])
          );
          setInSessionStorage("isDefault", response.data.result["isDefault"]);
          setInSessionStorage(
            "isDefaultPassword",
            response.data.result["isDefaultPassword"]
          );
          if (!response.data.result["isDefaultPassword"]) {
            if (
              response.data.result["userType"] === UserType.WarehouseEmployee
            ) {
              window.location.pathname = "/warehouse/purchaseOrder";
            } else {
              window.location.pathname = "/clients";
            }
            setOpen(true);
            setSnackbarMessage("Logged in successfully");
            setSnackbarSeverity("success");
          } else {
            setChangePasswordDialogOpen(true);
          }
        })
        .catch((error) => {
          console.log("Failed to log in");
          setOpen(true);
          setSnackbarMessage(error.response.data.result[0].message);
          setSnackbarSeverity("error");
        });
    }
  };

  const handleDialogClose = () => {
    setChangePasswordDialogOpen(false);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleChangeDefaultPasswordRequest = () => {
    if (
      validator.isEmpty(changePasswordText) ||
      !validator.isLength(changePasswordText, { min: 6, max: 20 })
    ) {
      setdefaultPasswordError(true);
      setPasswordHelperText(
        "Invalid password. Password must have atleast 6 characters."
      );
      return;
    }
    if (
      getFromSessionStorage("token") !== null ||
      getFromSessionStorage("token") !== ""
    ) {
      axios.defaults.headers.common["Authorization"] = getFromSessionStorage(
        "token"
      ) as string;
    }
    axios
      .put(
        auth.changeDefaultPassword,
        {
          password: changePasswordText,
        },
        {
          params: {
            branch: getFromSessionStorage("branchID"),
          },
        }
      )
      .then((response) => {
        window.location.pathname = "/clients";
        setOpen(true);
        setSnackbarMessage("Logged in successfully");
        setSnackbarSeverity("success");
      })
      .catch((error) => {
        console.log("Error changing password", error.response.data.result);
        setOpen(true);
        setSnackbarMessage(error.response.data.result[0].message);
        setSnackbarSeverity("error");
      });
  };

  return (
    <MainBox>
      <LogoBox>
        <Typography
          textAlign="center"
          color="primary"
          letterSpacing="1.25rem"
          variant="h2"
        >
          {CompanyName.billingName}
          {/* APLE <br />
          <br /> LIGHTS */}
        </Typography>
      </LogoBox>

      <Card
        elevation={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
          p: { sm: 4, md: 5 },
          m: { sm: 2, md: 4 },
          width: { sm: "50%", md: "30%" },
          borderRadius: 2.5,
          boxShadow: 4,
        }}
      >
        <LoginFormBox>
          <Typography sx={{ mb: 2 }} fontWeight="bold" variant="h4">
            Login
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            label="Phone Number"
            required
            margin="dense"
            helperText={phoneNumberHelperText}
            error={phoneNumberError}
            onChange={(e) => {
              setPhoneNumberError(false);
              setPhoneNumber(e.target.value);
              setphoneNumberHelperText("");
            }}
          />
          <TextField
            fullWidth
            type="password"
            variant="outlined"
            label="Password"
            required
            onKeyDown={handleSearchWithEnterKeyPress}
            helperText={passwordHelperText}
            error={passwordError}
            margin="dense"
            onChange={(e) => {
              setPasswordError(false);
              setpassword(e.target.value);
              setPasswordHelperText("");
            }}
          />
          <Button size="small" sx={{ mt: 2, p: 1 }}>
            Forgot password?
          </Button>
          <Button
            size="large"
            sx={{ mt: 2, width: "auto", pl: 2, pr: 2 }}
            variant="contained"
            onClick={loginSubmitButtonHandler}
          >
            Login
          </Button>
        </LoginFormBox>
      </Card>
      <Dialog open={changePasswordDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Change default password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please change your default password
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Password"
            type={!showPassword ? "password" : "text"}
            fullWidth
            helperText={passwordHelperText}
            error={defaultPasswordError}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(event) => {
              setPasswordError(false);
              setPasswordHelperText("");

              setChangePasswordText(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleChangeDefaultPasswordRequest}>Submit</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          severity={snackbarSeverity}
          onClose={handleSnackbarClose}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </MainBox>
  );
}

export default Login;
