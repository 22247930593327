import { Box, Card, Skeleton, Stack, styled } from "@mui/material";
import React from "react";

const RootBox = styled(Box)({
  margin: "2%",
});

function ListsSkeleton() {
  return (
    <RootBox>
      <Stack spacing={1}>
        <Card elevation={2} sx={{ p: 2, borderRadius: 2, mt: 2, mb: 2 }}>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        </Card>
      </Stack>
      <Stack sx={{ mt: 2 }} spacing={1}>
        <Card elevation={2} sx={{ p: 2, borderRadius: 2, mt: 2, mb: 2 }}>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        </Card>
      </Stack>
      <Stack sx={{ mt: 2 }} spacing={1}>
        <Card elevation={2} sx={{ p: 2, borderRadius: 2, mt: 2, mb: 2 }}>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        </Card>
      </Stack>
    </RootBox>
  );
}

export default ListsSkeleton;
