export const backendConfig = {
  //baseURL: "http://192.168.1.2:4000/",
  // baseURL: "http://localhost:4000/",
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://b.aa.lytpro.in/"
      : "http://192.168.1.2:4010/",
};

export const DefaultFontSize = "min(16px, calc(0.5vw + 0.8vh + 0.5vmin))";

export const enum DCTypes {
  Sample = "Delivery Challan (Sample)",
  Replacement = "Delivery Challan (Replacement)",
  Standard = "Delivery Challan",
}

export const CompanyName = {
  companyName: "Aple Lites",
  billingName: "Aple Lites",
  accountNumber: "041383800000705",
  ifscCode: "YESB0000413",
  branch: "SP Road, Secunderabad",
  gstno: "36ACAPK3373J1ZL",
  billingAddress: "4-2-23 & 34, RP ROAD, \n Secunderabad-500003(T.S)",
  email: "aplelites15@gmail.com",
  mobile: "+91 8099051870",
  bankName: "Yes Bank",
  panNo: "ACAPK3373J",
};

export const auth = {
  signInUrl: "api/auth/signin",
  changePassword: "/api/user/password/update",
  resetPassword: "api/admin/user/resetPassword/",
  changeDefaultPassword: "/api/user/password/changeDefaultPassword",
};

export const userManagementServices = {
  createUser: "api/admin/user/create/",
  listOfUsers: "api/admin/user/list/all/",
  userFilterOptions: "api/admin/user/filterOptions/",
  userConstants: "api/admin/user/constants",
  userSearchQuery: "api/admin/user/search/",
  userListCount: "api/admin/user/list/count/",
  disableUser: "api/admin/user/disable/",
  enableUser: "api/admin/user/enable/",
  updateUser: "api/admin/user/update/",
  deleteUser: "api/admin/user/delete/",
};

export const skuServices = {
  skuCreate: "api/sku/create",
  getSkuList: "api/sku/list/all/",
  getSkuCount: "api/sku/list/count/",
  deleteSKU: "api/sku/delete/",
  updateSKU: "api/sku/update/",
  getSkuDetails: "api/sku/detail/",
  searchSku: "api/sku/search/",
  createCustomField: "api/sku/create/customField/",
  updateCreatedCode: "api/sku/update/createdCode/",
  getAllSkuCodes: "api/sku/codes",
  getCostPriceEntry: "api/sku/costPrice/entry/",
  updateCostPrice: "api/sku/costPrice/",
  editImage: "api/sku/update/image/",
};

export const BoqServices = {
  createBoq: "api/boq/create/",
  getBoqDetailsByQuoteId: "api/boq/detail/",
  update: "api/boq/update/",
};

export const clientServices = {
  getClientList: "api/client/list/all/",
  getClientCount: "api/client/list/count/",
  getClientDetail: "api/client/detail/",
  clientUpdate: "api/client/update/",
  deleteClient: "api/client/delete/",
  createClient: "api/client/create/",
  clientDetails: "api/client/detail/",
  getQuotesOfProject: "api/client/project/quote/",
  createProject: "api/client/project/create/",
  deleteProject: "",
  updateProject: "api/client/project/update/",
  searchClient: "api/client/search/",
};

export const QuoteServices = {
  createQuote: "api/quote/create/",
  createQuoteRetail: "api/quote/create/retail",
  getQuotationsList: "api/quote/list/all/",
  getQuotationCount: "api/quote/list/count/",
  getProductsList: "/api/sku/list/all/quote/",
  getQuoteDetails: "api/quote/detail/",
  getConstantsForQuote: "api/quote/constants/",
  acceptedStatus: "api/quote/approve/",
  rejectedStatus: "api/quote/reject/",
  closeStatus: "api/quote/close/",
  updateProductList: "api/quote/update/productList",
  updateQuote: "api/quote/update/",
  costPrice: "api/quote/costPrice/",
  paymentReceived: "api/quote/paymentReceived/",
  defaults: "api/quote/defaults/",
  getPoForQuote: "api/quote/list/all/po/",
  getDcForQuote: "api/quote/list/all/dc/",
  getQhForQuote: "api/quote/list/all/qh/",
  getQhDetailsForQuote: "/api/quoteHistory/detail/",
  getSrForQuote: "api/quote/list/all/sr/",
  generateMasterQuote: "api/quote/masterQuote/",
  deleteQuotationHistory: "api/quote/delete/qh/",
};

export const createQuoteServices = {
  getClientListForQuotes: "api/client/list/all/quote/",
  getUsersListForQuotes: "api/user/list/all/quote/",
};

export const LedgerServices = {
  getLedger: "api/ledger/get",
};

export const PoServices = {
  getPoList: "api/po/list/all/",
  getPoListCount: "api/po/list/count/",
  getPoDetails: "api/po/detail/",
  verifySuccessfull: "api/po/verify/success",
  verifyFailed: "api/po/verify/failed",
};

export const DeliveryChallanServices = {
  creatDeliveryChallan: "api/dc/create/",
  createSampleDC: "api/dc/create/sample",
  createReplaceDC: "api/dc/create/replacement",
  getDcList: "api/dc/list/all/",
  getCountForDcList: "api/dc/list/count/",
  getDcDetails: "api/dc/detail/",
  editDcDetails: "api/dc/update/",
  receiptUpload: "api/dc/upload/receipt/",
  closeDC: "api/dc/close/",
};

export const SalesReturnServices = {
  createSalesReturn: "api/sales-return/create/",
  getSRByID: "/api/sales-return/detail/",
};

export const WarehouseServices = {
  getPoList: "api/po/list/all/",
  getPoListCount: "api/po/list/count/",
};

export const colorPalatte = {
  primaryColor: "#1976d2",
};

export enum UserType {
  Employee = "employee",
  Admin = "admin",
  Owner = "owner",
  WarehouseEmployee = "warehouseEmployee",
}

export enum ClientType {
  Architect = "architect",
  Other = "other",
}
